import Link from 'next/link'
import { VideoSpecificType } from '../lib/video'
import IconPlayFill from './icons/IconPlayFill'
import Image from 'next/image'

export const Video = ({ img, link, title }: VideoSpecificType) => {
  return (
    <Link href={link ?? '/'} className='inline-block md:w-full md:h-1/2'>
      <div className='relative min-h-40 md:size-full flex flex-col shadow-sm hover:shadow-md transition-all overflow-hidden rounded-sm max-992:w-auto'>
        <div className='min-h-40 max-h-60 size-full relative'>
          <Image width={300} height={300} src={img ?? ''} alt={`image-${link}`} className='h-full object-cover' />
        </div>
        <span className='bg-primary-500/70 w-12 h-12 flex justify-center items-center rounded-full absolute top-[calc(50%-1.5rem)] right-[calc(50%-1.5rem)]'>
          <IconPlayFill width={32} height={32} className='text-white' />
        </span>
        <div className='absolute bottom-[2px] right-4 bg-opacity-60 text-white text-lg px-4 py-2 rounded font-medium'>
          {title}
        </div>
      </div>
    </Link>
  )
}

export const VideoSkeleton = () => {
  return (
    <div className='bg-gray-50 md:w-full md:h-1/2 animate-pulse flex items-center justify-center'>
      <div className='size-12 rounded-full bg-gray-200' />
    </div>
  )
}
